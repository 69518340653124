import React from "react";
import { AiFillGithub } from "react-icons/ai";

const Button = () => {
  return (
    <>
      <div className="button-container">
        <span className="cross-fade">GitHub</span>
      </div>
    </>
  );
};

export default Button;
